var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module-tokens" },
    [
      _vm.loading && (_vm.tokensData || _vm.hiddenTokens)
        ? _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: { type: "table" },
          })
        : _vm._e(),
      _vm.hasTokens && !_vm.dense
        ? _c("mew-module", {
            staticClass: "bgWalletBlock",
            attrs: {
              "has-elevation": "",
              subtitle: "My Tokens Value",
              "has-body-padding": false,
              title: _vm.totalTokensValue,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "rightHeaderContainer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                "offset-y": "",
                                rounded: "lg",
                                "content-class": "module-tokens-edit-menu",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ma-2",
                                                attrs: {
                                                  rounded: "",
                                                  color: "basic",
                                                  icon: "",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  medium: "",
                                                  color: "textDark",
                                                },
                                              },
                                              [_vm._v("mdi-dots-vertical")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1276857850
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                _vm._l(_vm.items, function (item, i) {
                                  return _c(
                                    "v-list-item",
                                    { key: i, on: { click: item.action } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pl-2 pr-4 d-flex align-center",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                dense: "",
                                                color: "basic",
                                                left: "",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item.title)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "moduleBody",
                  fn: function () {
                    return [
                      _c("balance-table", {
                        staticClass: "mb-4",
                        attrs: { "table-data": _vm.tokensData },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              629777678
            ),
          })
        : _vm._e(),
      _vm.hasTokens && _vm.dense
        ? _c("mew-table", {
            attrs: {
              "has-color": false,
              "table-headers": _vm.tableHeaders,
              "table-data": _vm.tokensData,
            },
          })
        : _vm._e(),
      _vm.emptyWallet
        ? _c("balance-empty-block", {
            attrs: { "is-tokens": "", "is-eth": _vm.isEthNetwork },
            on: {
              openAddCustomToken: function () {
                return _vm.toggleAddCustomToken(true)
              },
            },
          })
        : _vm._e(),
      _c("token-add-custom", {
        attrs: {
          close: _vm.toggleAddCustomToken,
          open: _vm.openAddCustomToken,
        },
      }),
      _c("token-remove-custom", {
        attrs: {
          close: _vm.toggleRemoveCustomToken,
          open: _vm.openRemoveCustomToken,
          "selected-token": _vm.selectedToken,
        },
      }),
      _c("token-edit-custom", {
        attrs: {
          close: _vm.toggleEditCustomToken,
          open: _vm.openEditCustomToken,
        },
        on: {
          addToken: _vm.toggleAddCustomToken,
          removeToken: _vm.openRemoveToken,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }